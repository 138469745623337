import React from "react";

type Props = {
  audience_favorite?: boolean;
  contest_name: string;
  nominee?: boolean;
  position?: number;
};

export default function RecipeRibbon(props: Props) {
  let label: string = null;
  if (props.position == 1) {
    label = `Vinner ${props.contest_name}`;
  } else if (props.position) {
    label = `${props.position}. plass ${props.contest_name}`;
  } else if (props.audience_favorite) {
    label = `Folkets favoritt ${props.contest_name}`;
  } else if (props.nominee) {
    label = `Finalist ${props.contest_name}`;
  }

  if (label) {
    return (
      <div className="recipe-ribbon">
        <span className="label">{label}</span>
      </div>
    );
  } else {
    return <></>;
  }
}
