import React from "react";

type Props = {
  rating: number;
  count?: number;
};

export default function RatingStars({ rating, count }: Props) {
  const star = (val: number) => {
    let type = "empty";

    if (val >= 1) {
      type = "full";
    } else if (val > 0) {
      type = "half";
    }

    return <span className={"star " + type} />;
  };

  const rounded = Math.round(rating * 2) / 2;

  return (
    <div className="rating">
      {star(rounded)}
      {star(rounded - 1)}
      {star(rounded - 2)}
      {star(rounded - 3)}
      {star(rounded - 4)}
      {count !== undefined && (
        <span className="count">{count} vurderinger</span>
      )}
    </div>
  );
}
