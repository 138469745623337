import React from "react";
import ReactPaginate from "react-paginate";

type Props = {
  hrefBuilder: (page: number) => string;
  onPageChange: (evt: { selected: number }) => void;
  page: number;
  pageCount: number;
};

export default function Pagination(props: Props) {
  const { hrefBuilder, onPageChange, page, pageCount } = props;

  return (
    <ReactPaginate
      hrefBuilder={hrefBuilder}
      forcePage={page - 1}
      disableInitialCallback={true}
      onPageChange={onPageChange}
      pageCount={pageCount}
      pageRangeDisplayed={3}
      marginPagesDisplayed={1}
      nextLabel="Neste side"
      previousLabel="Forrige side"
      activeClassName="current"
      containerClassName="pagination"
    />
  );
}
