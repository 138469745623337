import csrfToken from "./csrfToken";

function hiddenField(form: HTMLFormElement, name: string, value: string) {
  const hiddenField = document.createElement("input");
  hiddenField.type = "hidden";
  hiddenField.name = name;
  hiddenField.value = value;
  form.appendChild(hiddenField);
}

function postForm(
  path: string,
  params: facebook.AuthResponse,
  method = "post"
) {
  const form = document.createElement("form");
  form.method = method;
  form.action = path;

  hiddenField(form, "authenticity_token", csrfToken());

  for (const key in params) {
    if (Object.prototype.hasOwnProperty.call(params, key)) {
      hiddenField(form, key, params[key] as string);
    }
  }
  document.body.appendChild(form);
  form.submit();
}

export default function facebookLogin() {
  window.FB.getLoginStatus((response) => {
    if (response.status == "connected") {
      postForm("/innlogging/facebook", response.authResponse);
    }
  });
}
