import React from "react";
import RatingStars from "./RatingStars";
import RecipeRibbon from "./RecipeRibbon";

type Props = Recipe;

export default function RecipeCard(props: Props) {
  const imageStyle = () => {
    const image = props.image;

    if (!image) {
      return {};
    }

    return {
      backgroundImage: `url(${image.url})`,
      backgroundPosition: `${image.position.x}% ${image.position.y}%`
    };
  };

  return (
    <a href={props.url} className={"recipe recipe-" + props.category}>
      {props.contest && <RecipeRibbon {...props} />}
      <div className="image" style={imageStyle()}></div>
      <div className="recipe-content">
        {props.likes !== undefined && (
          <div className="likes">{props.likes}</div>
        )}
        {props.rating !== undefined && <RatingStars rating={props.rating} />}
        <div className="recipe-source">{props.recipe_source}</div>
        <h5>{props.name}</h5>
        {props.participant && (
          <div className="participant">
            <img
              className="profile-picture"
              src={props.participant.image_url}
              width="150"
              height="150"
              alt={props.participant.name}
            />
            <div className="name">{props.participant.name}</div>
          </div>
        )}
      </div>
    </a>
  );
}
