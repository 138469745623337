import React, { useState } from "react";

type Props = {
  userRating: number;
  saveRating: (rating: number) => void;
};

export default function RateRecipe(props: Props) {
  const [userRating, setUserRating] = useState(props.userRating || 5);
  const [hoverRating, setHoverRating] = useState<number | null>(null);

  const save = () => {
    props.saveRating(userRating);
  };

  const star = (val: number) => {
    const rating = hoverRating || userRating;

    let className = "star empty";
    if (rating >= val) {
      className = "star full";
    }
    return (
      <span
        className={className}
        onMouseOver={() => setHoverRating(val)}
        onMouseOut={() => setHoverRating(null)}
        onClick={() => setUserRating(val)}
      />
    );
  };

  return (
    <div className="rate-recipe">
      <p>Hvilken vurdering vil du gi denne oppskriften?</p>
      <div className="rating">
        {star(1)}
        {star(2)}
        {star(3)}
        {star(4)}
        {star(5)}
      </div>
      <button className="cta" onClick={save}>
        Lagre
      </button>
    </div>
  );
}
