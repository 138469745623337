import React, { useCallback, useState, useEffect, useRef } from "react";
import Pagination from "./Pagination";
import RecipeFilter from "./RecipeFilter";
import RecipesGrid from "./RecipesGrid";

type Props = {
  recipes: Recipe[];
  search: string;
  page: number;
  categories: RecipeCategory[];
  category: string;
  filter: string;
};

type State = FilterOptions & {
  page: number;
};

export default function Recipes({
  recipes,
  search: initialSearch,
  page: initialPage,
  categories,
  category: initialCategory,
  filter: initialFilter
}: Props) {
  const perPage = 18;
  const scrollRef = useRef<HTMLDivElement>(null);

  const filterOpts = (str: string) => ({
    bakeklubben: str.indexOf("bakeklubben") !== -1,
    nominees: str.indexOf("nominerte") !== -1,
    contest: str.indexOf("nbb") !== -1
  });

  const defaultState = useCallback(
    () => ({
      search: initialSearch || "",
      page: initialPage || 1,
      category: initialCategory || "alle",
      ...filterOpts(initialFilter || "bakeklubben+nominerte")
    }),
    [initialCategory, initialPage, initialSearch, initialFilter]
  );

  const [state, setState] = useState<State>(defaultState());

  useEffect(() => {
    const setStateFromHistory = (evt: PopStateEvent) => {
      evt.preventDefault();
      setState((evt.state as State) || defaultState());
    };

    window.addEventListener("popstate", setStateFromHistory);
    return () => window.removeEventListener("popstate", setStateFromHistory);
  }, [defaultState]);

  useEffect(() => {
    recipes.forEach((r) => (r.searchString = searchString(r)));
  }, [recipes]);

  const applyFilter = (
    opts: Partial<FilterOptions>,
    replaceState?: boolean
  ) => {
    const newState = { ...opts, page: 1 };

    if (newState.contest) {
      newState.nominees = false;
    }

    if (newState.nominees) {
      newState.contest = false;
    }

    setState((prevState) => ({ ...prevState, ...newState }));

    scrollToRecipes();

    if (window && window.history) {
      const historyState = { ...state, ...newState };
      if (replaceState) {
        window.history.replaceState(historyState, "", urlBuilder(newState));
      } else {
        window.history.pushState(historyState, "", urlBuilder(newState));
      }
    }
  };

  const categoryDescription = () => {
    if (state.category === "alle") {
      return null;
    }

    const category = categories.filter((c) => c.name === state.category)[0];

    return (
      <div className="category-description">
        <h2>{category.title}</h2>
        <div className="excerpt">
          <p>{category.description}</p>
        </div>
      </div>
    );
  };

  const filterStr = (opts: FilterOptions) =>
    [
      opts.bakeklubben ? "bakeklubben" : null,
      opts.nominees ? "nominerte" : null,
      opts.contest ? "nbb" : null
    ]
      .filter((e) => e != null)
      .join("+");

  const filteredRecipes = () => {
    const { category, bakeklubben, nominees, contest, search } = state;
    let filtered: Recipe[] = [];

    if (bakeklubben) {
      filtered = filtered.concat(recipes.filter((r) => !r.contest));
    }

    if (nominees) {
      filtered = filtered.concat(recipes.filter((r) => r.contest && r.nominee));
    } else if (contest) {
      filtered = filtered.concat(recipes.filter((r) => r.contest));
    }

    if (category !== "alle") {
      filtered = filtered.filter((r) => r.category === category);
    }

    if (search) {
      filtered = filtered.filter((r) => fuzzyMatch(r.searchString, search));
    }

    return filtered;
  };

  const fuzzyMatch = (str: string, query: string) => {
    const pattern = query
      .toLowerCase()
      .split(" ")
      .reduce((a, b) => a + ".*" + b);
    return new RegExp(pattern).test(str.toLowerCase());
  };

  const onPageChange = (evt: { selected: number }) => {
    const newState = { page: evt.selected + 1 };
    const historyState = { ...state, ...newState };
    setState(newState);
    scrollToRecipes();
    if (window && window.history) {
      window.history.pushState(historyState, "", urlBuilder(newState));
    }
  };

  const pageCount = () => Math.ceil(filteredRecipes().length / perPage);

  const pageHrefBuilder = (page: number) => urlBuilder({ page });

  const pagedRecipes = (recipes: Recipe[]) => {
    const page = state.page;
    return recipes.slice((page - 1) * perPage, page * perPage);
  };

  const searchString = (recipe: Recipe) => {
    let str = `${recipe.name} ${recipe.category}`;
    if (recipe.participant) {
      str += ` ${recipe.participant.name}`;
    }
    return str;
  };

  const scrollToRecipes = () => {
    if (!scrollRef.current || !window) {
      return;
    }
    const elem = scrollRef.current;
    const rect = elem.getBoundingClientRect();
    const bodyRect = document.body.getBoundingClientRect();
    const offset = rect.top - bodyRect.top - 30;

    if (window.scrollY > offset) {
      window.scrollTo(0, offset);
    }
  };

  const urlBuilder = (params: FilterOptions) => {
    const opts = { ...state, ...params };
    let str = `/oppskrifter/${opts.category}/${filterStr(opts)}/${opts.page}`;

    if (opts.search) {
      str += "?q=" + encodeURIComponent(opts.search);
    }

    return str;
  };

  const filtered = filteredRecipes();
  const recipesToShow = pagedRecipes(filtered);

  return (
    <div className="recipes-list" ref={scrollRef}>
      <RecipeFilter
        applyFilter={applyFilter}
        categories={categories}
        category={state.category}
        bakeklubben={state.bakeklubben}
        nominees={state.nominees}
        contest={state.contest}
        search={state.search}
        urlBuilder={urlBuilder}
      />
      <div className="main-content">
        {categoryDescription()}
        <RecipesGrid recipes={recipesToShow} />
        {filtered.length === 0 && (
          <div className="no-results">
            <p>Vi fant dessverre ingen treff på ditt søk.</p>
          </div>
        )}
        {filtered.length > perPage && (
          <Pagination
            hrefBuilder={pageHrefBuilder}
            onPageChange={onPageChange}
            page={state.page}
            pageCount={pageCount()}
          />
        )}
      </div>
    </div>
  );
}
