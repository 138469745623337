import React from "react";
import RecipeCard from "./RecipeCard";

type Props = {
  recipes: Recipe[];
};

export default function RecipesGrid({ recipes }: Props) {
  return (
    <div className="recipes-grid">
      {recipes.map((recipe) => (
        <RecipeCard key={recipe.id} {...recipe} />
      ))}
    </div>
  );
}
