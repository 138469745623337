import { Controller } from "stimulus";
import Swiper from "swiper";
// import "swiper/swiper-bundle.css";

export default class extends Controller {
  swiper: Swiper;

  connect() {
    setTimeout(() => {
      this.swiper = new Swiper(this.element as HTMLElement, {
        autoHeight: true,
        spaceBetween: 30,
        loop: true,
        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
          clickable: true
        }
      });
    }, 200);
  }

  next() {
    this.swiper.slideNext();
  }

  prev() {
    this.swiper.slidePrev();
  }

  disconnect() {
    this.swiper.destroy();
  }
}
