// Entry point for the build script in your package.json

import Rails from "@rails/ujs";
Rails.start();

// Responsive Embeds
import responsiveEmbeds from "./lib/responsiveEmbeds";
responsiveEmbeds();

// React
import { FC } from "react";
import * as Components from "./components";
import ReactRailsUJS from "react_ujs";
ReactRailsUJS.getConstructor = (className: string) =>
  Components[className] as FC;

// Stimulus
import { Application } from "stimulus";
import CarouselController from "./controllers/CarouselController";
import ExpandableController from "./controllers/ExpandableController";
import HeaderController from "./controllers/HeaderController";
const application = Application.start();
application.register("carousel", CarouselController);
application.register("expandable", ExpandableController);
application.register("header", HeaderController);

// Facebook login
import facebookLogin from "./lib/facebookLogin";
declare const window: Window & {
  facebookLogin: () => void;
};
window.facebookLogin = facebookLogin;
