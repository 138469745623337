import React, { useState } from "react";

type Props = {
  categories: RecipeCategory[];
  category: string;
  search: string;
  applyFilter: (opts: Partial<FilterOptions>, replaceState?: boolean) => void;
  bakeklubben: boolean;
  nominees: boolean;
  contest: boolean;
  urlBuilder: (opts: FilterOptions) => string;
};

export default function RecipeFilter({
  categories,
  category,
  search,
  applyFilter,
  bakeklubben,
  nominees,
  contest,
  urlBuilder
}: Props) {
  const [categoriesExpanded, setCategoriesExpanded] = useState(false);

  const clickHandler = (opts: FilterOptions) => {
    return (evt: React.ChangeEvent | React.MouseEvent) => {
      if ("href" in evt.target && evt.target.href) {
        evt.preventDefault();
      }
      applyFilter(opts);
    };
  };

  const handleSearch = (evt: React.ChangeEvent<HTMLInputElement>) => {
    applyFilter({ search: evt.target.value }, true);
  };

  const toggleCategories = () => {
    setCategoriesExpanded(!categoriesExpanded);
  };

  return (
    <nav className="recipe-filter">
      <div className="search">
        <input
          type="text"
          value={search}
          placeholder="Søk i oppskrifter"
          onChange={handleSearch}
        />
      </div>
      <div className="filters">
        <ul className="content">
          <li>
            <div className="checkbox">
              <input
                type="checkbox"
                id="filter-bakeklubben"
                name="bakeklubben"
                checked={bakeklubben}
                onChange={clickHandler({ bakeklubben: !bakeklubben })}
              />
              <label htmlFor="filter-bakeklubben">Bakeklubben</label>
            </div>
          </li>
          <li>
            <div className="checkbox">
              <input
                type="checkbox"
                id="filter-nominees"
                name="nominees"
                checked={nominees}
                onChange={clickHandler({ nominees: !nominees })}
              />
              <label htmlFor="filter-nominees">
                Norges beste bakst: Nominerte
              </label>
            </div>
          </li>
          <li>
            <div className="checkbox">
              <input
                type="checkbox"
                id="filter-contest"
                name="contest"
                checked={contest}
                onChange={clickHandler({ contest: !contest })}
              />
              <label htmlFor="filter-contest">
                Norges beste bakst: Alle bidrag
              </label>
            </div>
          </li>
        </ul>
      </div>
      <div
        className={
          "categories expandable " + (categoriesExpanded ? "expanded" : "")
        }>
        <h6 className="expand" onClick={toggleCategories}>
          Kategori
        </h6>
        <ul className="content">
          <li>
            <a
              href={urlBuilder({ category: "alle" })}
              className={category === "alle" ? "current" : ""}
              onClick={clickHandler({ category: "alle" })}>
              Alle
            </a>
          </li>
          {categories.map((c) => (
            <li key={c.id}>
              <a
                href={urlBuilder({ category: c.name })}
                className={category === c.name ? "current" : ""}
                onClick={clickHandler({ category: c.name })}>
                {c.title}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
}
