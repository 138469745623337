import React, { useState } from "react";
import csrfToken from "../lib/csrfToken";
import RatingStars from "./RatingStars";
import RateRecipe from "./RateRecipe";

type Props = {
  url: string;
  rating: number;
  rating_count: number;
  likes: number;
  liked: boolean;
  image: string;
};

type RatingResponse = {
  rating: number;
  recipe: {
    rating: number;
    rating_count: number;
  };
};

type LikeResponse = {
  likes: number;
};

export default function RecipeActions({
  url,
  rating: initialRating,
  rating_count: initialRatingCount,
  likes: initialLikes,
  liked: initialLiked,
  image
}: Props) {
  const [rating, setRating] = useState(initialRating);
  const [ratingCount, setRatingCount] = useState(initialRatingCount);
  const [likes, setLikes] = useState(initialLikes);
  const [liked, setLiked] = useState(initialLiked || false);
  const [userRating, setUserRating] = useState<number | null>(null);
  const [showRate, setShowRate] = useState(false);

  const rate = () => {
    const requestUrl = `${url}/vurder.json`;
    if (showRate) {
      setShowRate(false);
    } else {
      request<RatingResponse>("GET", requestUrl, {}, (response) => {
        setShowRate(true);
        setUserRating(response.rating);
      });
    }
  };

  const saveRating = (rating: number) => {
    const requestUrl = `${url}/vurder.json`;
    request<RatingResponse>(
      "POST",
      requestUrl,
      { rating: { rating: rating } },
      (response) => {
        setUserRating(response.rating);
        setRating(response.recipe.rating);
        setRatingCount(response.recipe.rating_count);
        setShowRate(false);
      }
    );
  };

  const shareFacebook = (evt: React.MouseEvent) => {
    evt.preventDefault();
    window.FB.ui({ method: "share", href: url });
  };

  const sharePinterest = (evt: React.MouseEvent) => {
    const shareUrl =
      "https://pinterest.com/pin/create/button/?url=" +
      encodeURIComponent(url) +
      "&media=" +
      encodeURIComponent(image);

    evt.preventDefault();
    window.open(shareUrl, "_blank");
  };

  const toggleLiked = () => {
    const requestUrl = `${url}/stem.json`;

    if (liked) {
      request<LikeResponse>("DELETE", requestUrl, {}, (response) => {
        setLiked(false);
        setLikes(response.likes);
      });
    } else {
      request<LikeResponse>("POST", requestUrl, {}, (response) => {
        setLiked(true);
        setLikes(response.likes);
      });
    }
  };

  const print = (evt: React.MouseEvent) => {
    evt.preventDefault();
    window.print();
  };

  return (
    <div className="recipe-actions">
      {!showRate && (
        <div className="content">
          {likes !== undefined && (
            <div className="count-button">
              <div className="buttons">
                <button
                  className={"cta " + (liked ? "disabled" : "")}
                  onClick={toggleLiked}>
                  Lik
                </button>
              </div>
              <div className="likes">{likes}</div>
            </div>
          )}
          {rating !== undefined && (
            <div className="count-button">
              <div className="buttons">
                <button
                  className={"cta " + (showRate ? "disabled" : "")}
                  onClick={rate}>
                  Gi karakter
                </button>
              </div>
              <RatingStars rating={rating} count={ratingCount} />
            </div>
          )}
          <a href={url} className="share facebook" onClick={shareFacebook}>
            Del på Facebook
          </a>
          {image && (
            <a href={url} className="share pinterest" onClick={sharePinterest}>
              Del på Pinterest
            </a>
          )}
          <a href={url} className="print" onClick={print}>
            Skriv ut
          </a>
        </div>
      )}
      {showRate && (
        <RateRecipe userRating={userRating} saveRating={saveRating} />
      )}
    </div>
  );

  function request<T>(
    method: string,
    requestUrl: string,
    data: unknown,
    callback: (response: T) => void
  ) {
    const authUrl = `${url}/authenticate`;

    const xhr = new XMLHttpRequest();
    xhr.open(method, requestUrl, true);
    xhr.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhr.setRequestHeader("X-CSRF-Token", csrfToken());
    xhr.onload = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        callback(JSON.parse(xhr.responseText) as T);
      } else if (xhr.status === 401) {
        document.location = authUrl;
      }
    };
    xhr.send(JSON.stringify(data));
  }
}
